html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: #f0f2f5 !important;
}


body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Roboto', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #f2f4f8;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

#root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr; // 80px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #f0f2f5;

// dtb theme
  .layout-content-header,header {
    background: #753055 !important;
    padding: 0;
    padding-left: 20px;
  }
  a{
    color: #753055 !important;
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #6B42A2;
    border:  #6B42A2;
  }
  .ant-menu-horizontal{
    background: none;
    color: #f29663;
    height: 60px;
    margin-left: 20px;

  
  }
  .ant-menu-submenu{
      
    height: 58px;
    align-items: center;
    //background: yellow !important;
  }
  .ant-menu-submenu-title {
    width: 100%;
    //background: deeppink;
    padding: 0;
    height: 58px;text-align: center;
   
    margin: 0;
    .submenu-title-wrapper {
      width: 100%;
      //background: yellow!important;
      height: 58px;
      width: 58px;
      vertical-align: middle;
      align-items: center;
      padding-top: 6px;
      
      text-align: center;
      
      .anticon {
        font-size: 18px;
        text-align: center;
        margin: auto;
    }
  }
}
}

label {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-form a {
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1024px) {
  body {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  #root {
    display: block;
    //overflow-y: scroll;
  }
}
